@import 'assets/styles/vars';

.list-path{
  display: flex;
  flex-direction: column;

  a{
    color: $white;
    text-decoration: none;
    font-weight: 300;
    font-size: 0.9rem;
    margin-bottom: 1rem;

    &:hover{
      text-decoration: underline;
    }
  }

  .title{
    font-weight: 500;
    font-size: 1rem;
  }

  .see-more{
    cursor: pointer;
    font-weight: 300;
    border: none;
    outline: none;
    background-color: transparent;
    color: $white;
    text-align: start;

    &:hover{
      text-decoration: underline;
    }
  }
}