@import 'assets/styles/vars';

.not-found{
  .container{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 3rem;

    @media screen and (min-width: 769px){
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0;
    }

    .left{
      order: 2;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1{
        font-size: 2rem;
        font-weight: 500;
      }
      
      p{
        margin: 1rem 0;
        font-weight: 300;
      }

      button{
        width: max-content;
      }

      @media screen and (min-width: 769px){
        order: 1;
        width: 50%;
        align-items: flex-start;

        h1{
          font-size: 2.5rem;
          max-width: 400px;
        }

        p{
          max-width: 400px;
        }
      }
    }
    .right{
      order: 1;

      img{
        width: 100%;
      }

      @media screen and (min-width: 769px){
        order: 2;
        width: 50%;
      }
    }
  }
}