@import 'assets/styles/vars';

.input{
  width: 100%;
  
  .label{
    margin-bottom: 0.5rem;
    margin-left: 8px;
    display: block;
    font-size: 0.9rem;

    &.t-error{
      color: red;
    }
  }

  input{
    border: 1px solid #C5C5C5;
    border-radius: 12px;
    padding: 0.85rem;
    width: 100%;
    font-weight: 300;

    &:focus{
      outline: none;
      border-radius: 12px;
      border: 1px solid $darkBlack;
    }

    &.t-error{
      border: 1px solid red;
    }
  }

  .error{
    font-size: 0.7rem;
    margin-left: 8px;
    display: block;
    color: red;
    font-weight: 300;
    margin-top: 0.5rem;
  }
}