@import 'assets/styles/vars';

.input_search-container{
  background-color: $white;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  border-color: rgba(#C5C5C5, 0.67);
  border-style: solid;
  border-width: 1px;
  padding: 0 1rem;
  position: relative;
  
  &.br-all{
    border-radius: 10px;
  }

  &.br-top{
    border-radius: 10px 10px 0 0;
  }

  .input{
    padding: 0.75rem;
    font-weight: 300;
    border: none;
    outline: none;
    width: 100%;
  }

  .close{
    width: 14px;
    height: 14px;
    display: flex;

    svg{
      background-color: $deepBlue;
      border-radius: 50%;
      padding: 0.1rem;
    }
  }

  .body{
    position: absolute;
    left: 0;
    background-color: $white;
    border: 1px solid $gray;
    z-index: 997;
    border-radius: 0 0 10px 10px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    font-size: 0.9rem;
    font-weight: 300;
    max-height: 315px;
  }
}