@import 'assets/styles/vars';

.footer{
  background: linear-gradient(90.02deg, $deepBlue 2.57%, #1E285B 35.95%, #213067 52.24%, #294682 99.11%);
  color: $white;

  .container{
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media screen and (min-width: 769px){
      gap: 0;
    }

    .body{
      display: flex;
      position: relative;
      justify-content: center;
      z-index: 995;

      @media screen and (min-width: 769px){
        justify-content: space-between;
      }

      .left{
        display: none;

        @media screen and (min-width: 769px){
          justify-content: space-between;
          display: grid;
          gap: 4rem;
        }
      }

      .right{
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 769px){
          display: none;
        }

        img{
          width: 100px;
          background-color: $gray;
          padding: 2rem 0.75rem;
          border-radius: 20px;
          margin-right: 1rem;

          @media screen and (min-width: 769px){
            width: 125px;
          }
        }
      }
    }

    .foot{
      font-weight: 300;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      position: relative;
      z-index: 994;

      @media screen and (min-width: 769px){
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: -50px;
      }


      div{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.5rem 1.5rem;
      }

      a{
        color: $white;
        text-decoration: none;
        text-align: center;
        
        &:hover{
          text-decoration: underline;
        }
      }

      .logo{
        display: none;
        
        @media screen and (min-width: 769px){
          width: 125px;
          display: block;
          background-color: $gray;
          padding: 2rem 0.75rem;
          border-radius: 20px;
          margin-right: 1rem;
        }
      }
    }
  }
}