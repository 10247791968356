@import 'assets/styles/vars';

.loading{
  &.complete{
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wrapper-spinner {
    position: relative;
    padding: 10px 20px;
    background-color: transparent;
    border: none;
    border-radius: 4px;
    cursor: not-allowed;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    top: 50%;
    left: 50%;
    width: 70px;
    height: 70px;
    border: 7px solid rgb(155, 213, 255);
    border-top: 7px solid #171d4a;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-top: -12px; 
    margin-left: -12px;
    display: none;
  }

  .wrapper-spinner .spinner {
    display: inline-block;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
