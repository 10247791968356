@import 'assets/styles/vars';

.product-tour{
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  min-height: 985px;

  @media screen and (min-width: 769px) {
    grid-template-columns: 30% 70%;
  }

  @media screen and (min-width: 900px) {
    grid-template-columns: 40% 60%;
  }

  @media screen and (min-width: 1024px){
    grid-template-columns: 45% 55%;
  }

  @media screen and (min-width: 1200px){
    grid-template-columns: 50% 50%;
  }
  
  .background{
    display: none;

    @media screen and (min-width: 769px) {
      display: block;
      background: url('../../assets/images/product-tour.webp');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right;
    }
  }

  .form{
    background-color: $white;
    height: 100%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (min-width: 769px){
      padding: 4rem;
    }

    @media screen and (min-width: 1024px){
      max-width: 700px;
    }

    h3{
      font-size: 2rem;
      font-weight: 700;
      color: $darkBlack;
    }

    p{
      font-weight: 300;
      color: $lightBlack;
    }

    .box{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 0.5rem;

      @media screen and (min-width: 769px){
        flex-direction: row;
      }
    }
  }

  .modal-finish{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    h2{
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
      color: $darkBlack;
    }

    p{
      font-weight: 500;
      max-width: 300px;
      text-align: center;
      margin-bottom: 1rem;
      color: $lightBlack;
    }
  }
}