.tooltip-container {
  position: relative;
}

.tooltip {
  display: none;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  z-index: 998;
}

.tooltip-trigger:hover + .tooltip {
  display: block;
}