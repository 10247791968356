$colors: (
  transparent: transparent,
  orange: $orange,
  deepBlue: $deepBlue,
  blue: $blue,
  darkBlue: $darkBlue,
  gray: $gray,
  extraGray: $extraGray,
  white: $white,
  black: $black,
  darkBlack: $darkBlack,
  lightGray: $lightGray,
  blackMedium: $blackMedium,
  lightBlack: $lightBlack,
  lightBlue: $lightBlue,
  green: $green,
);

@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: $value;
  }

  .color-#{$color} {
    color: $value;
  }
};

.bg-deepBlue-linear{
  background: linear-gradient(90.02deg, $deepBlue 2.57%, #1E285B 35.95%, #213067 52.24%, #294682 99.11%);
}

.bg-orange-linear{
  background: linear-gradient(110.22deg, #EE5E24 -2.51%, #454F74 57.36%, #131444 99.89%)
}

.bg-gray-linear{
  background: linear-gradient(180deg, #9CA8BA 0%, #5D6E87 53.65%, #1A2B45 100%)
}


$fonts-weight: (
  300: 300,
  500: 500,
  700: 700,
);

@each $font, $value in $fonts-weight {
  .fw-#{$font} {
    font-weight: $value;
  }
}

$border-radius: (
  6: 6px,
  8: 8px,
  10: 10px,
  12: 12px,
  16: 16px,
);

@each $radius, $value in $border-radius {
  .br-#{$radius} {
    border-radius: $value;
  }
}

$sizes: (
  0: 0rem,
  1: 0.25rem,
  2: 0.5rem,
  3: 0.75rem,
  4: 1rem,
  5: 1.25rem,
  6: 1.5rem,
  7: 1.75rem,
  8: 2rem,
  9: 2.25rem,
  10: 2.5rem,
  11: 2.75rem,
  12: 3rem,
  13: 3.25rem,
  14: 3.5rem,
  15: 3.75rem,
  16: 4rem,
);

@each $size, $value in $sizes {
  .fs-#{$size} {
    font-size: $value;
  }

  .p-#{$size} {
    padding: $value;
  }

  .pt-#{$size} {
    padding-top: $value;
  }

  .pb-#{$size} {
    padding-bottom: $value;
  }

  .pl-#{$size} {
    padding-left: $value;
  }

  .pr-#{$size} {
    padding-right: $value;
  }

  .px-#{$size} {
    padding-left: $value;
    padding-right: $value;
  }

  .py-#{$size} {
    padding-top: $value;
    padding-bottom: $value;
  }

  .m-#{$size} {
    margin: $value;
  }

  .mt-#{$size} {
    margin-top: $value;
  }

  .mb-#{$size} {
    margin-bottom: $value;
  }

  .ml-#{$size} {
    margin-left: $value;
  }

  .mr-#{$size} {
    margin-right: $value;
  }

  .mx-#{$size} {
    margin-left: $value;
    margin-right: $value;
  }

  .my-#{$size} {
    margin-top: $value;
    margin-bottom: $value;
  }
}

$aligns: (
  center: center,
  left: left,
  right: right,
);

@each $align, $value in $aligns {
  .ta-#{$align} {
    text-align: $value;
  }

  .ta-mobile-#{$align} {
    @media screen and (max-width: 768px) {
      text-align: $value;
    }
  }
}

$rotates :(
  90: 90deg,
  180: 180deg,
  270: 270deg,
);

@each $rotate, $value in $rotates {
  .rotate-#{$rotate} {
    transform: rotate($value);
  }
}

$textTransforms: (
  uppercase: uppercase,
  lowercase: lowercase,
  capitalize: capitalize,
);

@each $textTransform, $value in $textTransforms {
  .tt-#{$textTransform} {
    text-transform: $value;
  }
}

.c-pointer{
  cursor: pointer;
}

.c-default{
  cursor: default !important;
}

.only-mobile{
  display: initial;

  @media screen and (min-width: 769px) {
    display: none;
  }
}

.only-desktop{
  display: none;

  @media screen and (min-width: 769px) {
    display: initial;
  }
}

.w-auto{
  width: auto !important;
}