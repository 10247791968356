@import 'assets/styles/vars';

.modal{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.6);
  backdrop-filter: blur(2px);
  z-index: 998;
  opacity: 1;

  .content{
    background-color: $white;
    margin: 0.5rem;
    padding: 3.5rem 1.5rem 3.5rem;
    border-radius: 8px;
    width: 100%;
    position: relative;

    @media (min-width: 360px) {
      width: 480px;
    }

    .close{
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
    }
  }
}