@import 'assets/styles/vars';

.pagination{
  
  .container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 1rem;
    gap: 1rem;

    button{
      cursor: pointer;
      box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background-color: $white;

      &.item{
        border: 1px solid $black;
        padding: 0.9rem 0.3rem;
        width: 3rem;

        &.active{
          background-color: $orange;
          color: $white;
          border-color: $orange;
        }

        &:hover{
          background-color: $orange;
          border-color: $orange;
          color: $white;
        }
      }

      &.no-item{
        background-color: $white;
        padding: 0.9rem 0.3rem;
        width: 3rem;

        &:hover{
          background-color: $white;
        }
      }

      &.arrow{
        padding: 0.25rem;
        display: block;
        border: 1px solid $black;

        &:hover{
          background-color: $gray;
        }

        &.inactive{
          opacity: 0.2;
          cursor: not-allowed;
        }
      }
    }
  }
}