@import 'assets/styles/vars';

.floating-menu{
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  position: relative;

  &.active{
    .link{
      height: 100%;
      display: block;
      border-bottom: 6px solid $orange;
    }
  }

  .link{
    border-bottom: 6px solid transparent;
    color: $deepBlue;
    font-weight: 500;
    display: block;
    text-decoration: none;
    font-size: 1rem;
    padding: 1rem 0.25rem;
    white-space: nowrap;
  }

  .body{
    position: absolute;
    left: 1.25rem;
    background-color: $gray;
    z-index: 997;
    padding: 0.5rem 0 1rem;
    border-radius: 0 0 5px 5px;
    display: flex;
  
    .grid-row{
      display: flex;
      flex-direction: column;
    }

    .item{
      width: max-content;
      max-width: 225px;
      height: max-content;
    }

    a{
      color: $darkBlack;
      padding: 0.95rem 1.7rem;
      font-weight: 500;
      display: block;
      text-decoration: none;
      font-size: 1rem;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}