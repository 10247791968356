@import 'assets/styles/vars';

.information{
  .container{
    padding: 2rem 1rem;

    *{
      margin-bottom: 0.5rem;
    }

    h1{
      font-size: 1.8rem;
      color: $darkBlack;
    }

    h2{
      font-size: 1.7rem;
      color: $darkBlack;
    }

    h3{
      font-size: 1.6rem;
      color: $darkBlack;
    }

    h4{
      font-size: 1.5rem;
      color: $darkBlack;
    }

    h5{
      font-size: 1.4rem;
      color: $darkBlack;
    }

    h6{
      font-size: 1.3rem;
      color: $darkBlack;
    }

    span{
      font-size: inherit;
    }

    a,
    ul,
    ol,
    p,
    li{
      font-size: 1rem;
      color: $lightBlack;
    }

    ul, ol{
      margin-left: 1rem;
    }
  }
}