@import 'assets/styles/vars';

.info-card{
  .container{
    padding: 3rem 2rem;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 2rem;

    @media screen and (min-width: 769px){
      grid-template-columns: repeat(2, 1fr);
      padding: 2.5rem;
      gap: 4rem;
    }

    @media screen and (min-width: 1024px){
      gap: 8rem;
    }

    .left{
      order: 2;

      h2{
        font-size: 1.8rem;

        @media screen and (min-width: 769px){
          font-size: 2rem;
        }
      }

      p{
        font-size: 0.9rem;
        font-weight: 300;
        margin: 1.5rem 0;
        line-height: 1.3rem;

        @media screen and (min-width: 769px){
          font-size: 1rem;
        }
      }

      @media screen and (min-width: 769px){
        order: 1;
      }

      @media screen and (min-width: 1024px){
        max-width: 90%;
      }
    }

    .right{
      order: 1;

      img{
        object-fit: cover;
        width: 100%;
        vertical-align: middle;
        border-radius: 5px;
      }
    
      @media screen and (min-width: 769px){
        order: 2;
        max-width: 420px;
        height: 239px;

        img{
          height: 100%;
        }
      }
    }
  }
}