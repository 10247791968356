@import 'assets/styles/vars';

.accordion{
  display: flex;
  flex-direction: column;

  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    &.active{
      background-color: $deepBlue;

      a{
        color: $white;
        font-weight: 700;
      }
    }

    a{
      font-size: 1.15rem;
      font-weight: 500;
      text-decoration: none;
      color: $deepBlue;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .body{
    .item{
      padding: 1rem 0 1rem 2.5rem;

      &.active{
        background-color: $deepBlue;
  
        a{
          color: $white;
          font-weight: 700;
        }
      }

      a{
        font-size: 1.15rem;
        font-weight: 500;
        text-decoration: none;
        color: $deepBlue;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
