@import 'assets/styles/vars';

.header{
  background: linear-gradient(89.93deg, #E5E5E5 7%, #D9D9D9 25.03%, #CACACA 48.62%, #BCBCBC 73.16%, #B3B3B3 93.39%);

  .container{
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
    gap: 1rem;

    .left{
      display: flex;
      align-items: center;

      .icon-menu{
        margin-right: 1rem;
      }
      
      @media screen and (min-width: 769px) {
        gap: 1.5rem;
        align-items: flex-end;
      }
    }

    .right{
      display: flex;
      align-items: center;
      gap: 1rem;

      button{
        padding-left: 2.5rem;
        padding-right: 2.5rem;

        @media screen and (min-width: 850px) {
          white-space: nowrap;
        }
      }
    }

    .body-search{
      display: flex;
      flex-direction: column;
  
      .title{
        font-size: 0.9rem;
        padding: 0.6rem 1rem;
      }

      .result{
        overflow: hidden;
        padding: 0.6rem 1rem;
        color: $darkBlack;
        text-decoration: none;
        font-weight: 500;

        &:hover{
          background-color: $gray;
        }
      }
    }
  }

  .logo{
    vertical-align: middle;
    padding: 0.5rem 0;
    width: 85px;

    @media screen and (min-width: 769px){
      width: 130px;
    }
  }
}