@import 'assets/styles/vars';

.carousel-wrapper{
  background: $deepBlue;
  color: $black;

  .carousel{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;
    overflow: hidden;

    .content{
      overflow: hidden;
      width: 100%;

      .items{
        display: flex;
        position: relative;
        align-items: center;
        justify-content: start;
    
        .item{
          transition: .3s ease all;
          z-index: 995;
          position: relative;
        }
      }
    }

    .arrow{
      &-left,
      &-right{
        position: absolute;
        z-index: 999;
        filter: drop-shadow(rgb(190, 190, 190) 0px 0px 3px);
      }

      &-left{
        left: 0;
      }

      &-right{
        right: 0;
      }
    }

    .dots{
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 996;
      margin-bottom: 1rem;

      .dot{
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        margin: 0 0.3rem;
        cursor: pointer;
        transition: .2s ease all;
        box-shadow: 0 0 5px 0px rgba(58, 58, 58, 0.5)
      }
    }
  }
}