@import 'assets/styles/vars';

.textarea{
  width: 100%;

  label{
    margin-bottom: 0.5rem;
    display: block;
    font-size: 0.9rem;
    margin-left: 8px;
  }

  textarea{
    border: 1px solid #C5C5C5;
    border-radius: 12px;
    padding: 1rem;
    width: 100%;
    height: 105px;
    resize: none;
    font-weight: 300;

    &:focus{
      outline: none;
      border-radius: 12px;
      border: 1px solid $darkBlack;
    }
  }
}