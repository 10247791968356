@import 'vars';
@import 'base';

@font-face {
  font-family: "Aquawax";
  font-weight: 700;
  src: url("../fonts/Aquawax\ Black\ Trial.ttf") format("truetype");
  unicode-range: U+41-5A, U+61-7A;
}

@font-face {
  font-family: "Aquawax";
  font-weight: 500;
  src: url("../fonts/Aquawax\ Medium\ Trial.ttf") format("truetype");
  unicode-range: U+41-5A, U+61-7A;
}

@font-face {
  font-family: "Aquawax";
  font-weight: 300;
  src: url("../fonts/Aquawax\ Light\ Trial.ttf") format("truetype");
  unicode-range: U+41-5A, U+61-7A;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0px;
  font-family: "Aquawax", Roboto;
  font-size: 14px;
  scrollbar-color: #9f9f9f transparent;

  @media screen and (min-width: 769px) {
    font-size: 16px;
  }
}

*::-webkit-scrollbar-thumb {
  background-color: #9f9f9f;
  border-radius: 10px;
}
*::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

.container{
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  scroll-behavior: smooth;
}
