@import 'assets/styles/vars';

.navbar-mobile{
  .content{
    display: grid;
    grid-template-rows: auto 1fr;
  }

  .top{
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;

    .icon-menu{
      margin-right: 1rem;
    }

    .logo{
      vertical-align: middle;
      width: 85px;
    }
  }

  .body{
    overflow-y: auto;

    .body-search{
      display: flex;
      flex-direction: column;
  
      .title{
        font-size: 0.9rem;
        padding: 0.6rem 1rem;
      }
  
      .result{
        overflow: hidden;
        padding: 0.6rem 1rem;
        color: $darkBlack;
        text-decoration: none;
        font-weight: 500;
  
        &:hover{
          background-color: $gray;
        }
      }
    }
  }
}