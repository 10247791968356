@import 'assets/styles/vars';

.checkbox{
  .wrapper{
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;

    input[type="checkbox"]{
      cursor: pointer;
      width: 24px;
      height: 24px;

      &.error{
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 1px solid red;
        border-radius: 3px;
      }
    }

    label{
      cursor: pointer;
      font-weight: 300;
      font-size: 0.9rem;

      .link{
        color: $darkBlack;
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }
}