@import 'assets/styles/vars';

.home_carousel_item{
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 769px){
    max-height : 80vh;
  }

  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}