@import 'assets/styles/vars';

.point-card{

  .container{
    padding: 4rem 2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    @media screen and (min-width: 769px){
      grid-template-columns: repeat(2, 1fr);
      padding: 3rem;
      gap: 4rem;
    }

    @media screen and (min-width: 1024px){
      gap: 8rem;
    }

    .left{
      img{
        width: 100%;
        object-fit: contain;
        max-height: 469px;
        vertical-align: middle;
        border-radius: 10px;

        @media screen and (min-width: 769px) {
          object-fit: cover;
          width: 418px;
          height: 469px;
        }
      }
    }

    .right{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      h2{
        font-size: 2rem;
        font-weight: 500;
      }

      ul{
        width: 100%;
        padding-left: 1rem;
      }
    
      li{
        font-size: 1.2rem;
        font-weight: 300;
        color: $lightBlack;
        line-height: 1.5rem;

        @media screen and (min-width: 769px){
        font-size: 1rem;
        }
      }

      button{
        margin-top: 1rem;
  
        @media screen and (min-width: 769px){
          width: max-content;
          margin-top: auto;
        }
      }
    }
  }
}