@import 'assets/styles/vars';

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: rgba(0,0,0,.6);
  backdrop-filter: blur(1px);
  z-index: 997;
  opacity: 1;

  .content{
    background: linear-gradient(92.87deg, #E5E5E5 -0.1%, #D9D9D9 57.99%, #CACACA 71.24%, #BCBCBC 88.38%, #B3B3B3 100%);

    height: 100%;
    width: 100%;
  
    @media (min-width: 480px) {
      width: 350px;
    }
  }
}
